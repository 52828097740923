.choose-pet-modal {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 30px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 600px;
  max-height: 90%;
  min-height: 200px;

  .pets-container {
    display: flex;
    flex-direction: column;
    padding: 20px 40px;
    overflow: auto;
  }
}

.choose-pet-close-bttn {
  position: absolute;
  right: 40px;
  top: 40px;
}
