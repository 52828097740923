.about-us {
  position: relative;
  height: calc(calc(var(--vh, 1vh) * 100) - 86px);
  background: linear-gradient(51.64deg, #7367f0 25.43%, #857bf2 55.41%, #9e95f5 96.09%);

  .decore-left {
    position: absolute;
    left: 0px;
    width: 360px;
    max-width: 45%;
  }

  .decore-right {
    position: absolute;
    right: 0px;
    width: 360px;
    max-width: 45%;
  }

  .txt-section {
    width: 100%;
    max-width: 450px;
    position: absolute;
    top: 50%;
    left: 10%;
    transform: translate(0%, -50%);
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .about-us-image {
    position: absolute;
    bottom: 0px;
    right: 15%;
    width: 50%;
    max-width: 670px;
  }
}

@media only screen and (max-width: 360px) {
  .about-us-image {
    display: none;
  }
}

@media only screen and (max-width: 595px) {
}

/* Small screen devices (600px and above) */
@media only screen and (max-width: 600px) {
  .txt-section {
    width: 100% !important;
  }
}

/* Medium screen devices (768px and above) */
@media only screen and (max-width: 768px) {
}

/* Big screen devices (890px and above) */
@media only screen and (max-width: 890px) {
  .about-us {
    height: calc(calc(var(--vh, 1vh) * 100) - 86px - 64px);

    .txt-section {
      width: 80% !important;
      top: 120px !important;
      left: 50% !important;
      transform: translate(-50%, 0%) !important;
      align-items: center;
      text-align: center;
    }

    .decore-left {
      max-width: 35% !important;
    }

    .decore-right {
      max-width: 35% !important;
    }

    .about-us-image {
      transform: translate(50%, 0%) !important;
      right: 50% !important;
    }
  }
}
