.main-div {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.content-wrapper {
  margin-top: 86px;
  min-height: calc(calc(var(--vh, 1vh) * 100) - 86px);
  background-color: #fbfcff;
  display: flex;
  flex-direction: column;
}

.main-spinner {
  margin: auto;
}

@media only screen and (max-width: 595px) {
}

/* Small screen devices (600px and above) */
@media only screen and (max-width: 600px) {
}

/* Medium screen devices (768px and above) */
@media only screen and (max-width: 768px) {
}

/* Big screen devices (890px and above) */
@media only screen and (max-width: 890px) {
  .content-wrapper {
    margin-bottom: 64px;
    min-height: calc(calc(var(--vh, 1vh) * 100) - 86px - 64px);
  }
}
