.search-result-item {
  padding: 16px 0px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 24px;
  border-bottom: 0.5px dashed #b5aff3;

  .search-result-txt-div {
    width: 100%;
    max-width: 250px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    text-align: start;

    .details-div {
      display: flex;
      justify-content: space-between;
      gap: 24px;
    }

    .details-item {
      display: flex;
      gap: 8px;
      align-items: center;
      
      svg {
        width: 32px;
        height: 32px;
      }
    }
  }
}
