.create-pet-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

@media only screen and (max-width: 595px) {
}

/* Small screen devices (600px and above) */
@media only screen and (max-width: 600px) {
}

/* Medium screen devices (768px and above) */
@media only screen and (max-width: 768px) {
}

/* Big screen devices (890px and above) */
@media only screen and (max-width: 890px) {
}

/* Big screen devices (1200px and above) */
@media only screen and (max-width: 1200px) {
}
