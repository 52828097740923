.contests-page {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  .content {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    gap: 32px;
    padding-bottom: 64px;
  }

  .contest-type-wrapper {
    margin-bottom: 12px;
  }

  .no-contests-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 595px) {
}

/* Small screen devices (600px and above) */
@media only screen and (max-width: 600px) {
}

/* Medium screen devices (768px and above) */
@media only screen and (max-width: 768px) {
}

/* Big screen devices (890px and above) */
@media only screen and (max-width: 890px) {
  .contest-type-wrapper {
    display: none;
  }
}
