.my-bonus-votes-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  .my-bonus-votes {
    flex: 1;
    width: 90%;
    max-width: 900px;
    display: flex;
    flex-direction: column;

    .bonus-vote-header {
      width: 100%;
      text-align: center;
      background-color: #f6f7fa;
      padding: 6px;
    }

    .bonus-vote-content {
      width: 100%;
      max-width: 550px;
      margin: 24px auto;
      display: flex;
      justify-content: space-between;
      gap: 8px;
      align-items: center;

      .left-side {
        display: flex;
        align-items: center;
        gap: 12px;
      }
    }
  }
}
