.input-radio-wrapper {
  .label-div {
    display: flex;
    align-items: center;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}
