.contest-pet-card {
  position: relative;
  width: 100%;
  height: 500px;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
  border-radius: 30px;

  .pet-photo-wrapper {
    position: absolute;
    border-radius: 30px;
    overflow: hidden;
    height: 100%;
    width: 55%;
  }

  .txt-div {
    position: absolute;
    right: 0px;
    width: 550px;
    height: 100%;
    background-color: white;
    max-width: 500px;
    border-radius: 30px 30px 30px 0px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .top-section {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .name-div {
      display: flex;
      align-items: center;
      gap: 12px;
    }

    .state-div {
      display: flex;
      align-items: center;
      gap: 8px;

      svg {
        font-size: 24px;
        color: #e4e1f3;
      }
    }

    .chip-container {
      margin-top: 24px;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }

  .vote-button-div {
    display: flex;
    align-items: center;
    gap: 8px;

    svg {
      color: var(--purple);
    }
  }
}
