.search-wrapper {
  width: 100%;
}

.search-result-div {
  width: 100%;
  display: flex;
  gap: 16px;
  padding-bottom: 12px;
  border-bottom: 1px dashed #b5aff3;

  .search-result-txt-div {
    margin: auto 0px;
  }
}
