* {
  outline: none;
  box-sizing: border-box;
  margin: 0px;
}

html {
}

body {
  width: 100%;
  height: calc(var(--vh) * 100);
  background: #fbfcff;
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --black: #3b3c43;
  --purple: #7e73f2;
  --border: #d9d9d9;
}

#root {
  height: 100%;
}

button {
  font-family: Poppins;
  border: none;
  background-color: transparent;
  padding: 0px;
  color: black;

  &:active {
    opacity: 0.9;
  }

  &:disabled {
    opacity: 0.5;
  }
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
}

.MuiTypography-subtitle2 {
  font-weight: 400 !important;
  color: #3b3c43;
}

.mobile-show {
  display: none;
}

/* Big screen devices (890px and above) */
@media only screen and (max-width: 890px) {
  .MuiTypography-h3 {
    width: 100%;
    padding: 16px 5% 24px !important;
    margin: 0px !important;
  }

  .mobile-hide {
    display: none !important;
  }

  .mobile-show {
    display: flex !important;
  }
}

@media only screen and (max-width: 768px) {
}

@media (hover: hover) {
  a:hover,
  button:hover:enabled {
    opacity: 0.9;
    cursor: pointer;
  }
}
