.input-image {
  position: relative;
  min-height: 250px;
  width: 100%;
  max-width: 400px;
  margin: 0px auto;
  padding: 20px 0px;
  background: #f6f7fa;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg,
  img {
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }

  img {
    height: 75%;
    width: 75%;
  }

  input {
    display: none;
  }

  label {
    display: flex;
    justify-content: center;
  }

  .photo {
    height: 200px;
    width: 200px;
    border-radius: 30px;
  }

  .trash-bttn {
    color: var(--purple);
    background-color: white;
    position: absolute;
    bottom: 12px;
    right: 12px;
  }

  .helper-txt {
    position: absolute;
    bottom: 2px;
    left: 16px;
  }
}
