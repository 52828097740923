.contest-entry-card-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 32px 24px 20px;
  object-fit: cover;

  background-repeat: no-repeat;
  background-color: #9e95f5;
  background-position: 75% 100%;
  background-size: auto 90%;

  border-radius: 30px;
  width: 100%;
  max-width: 400px;
  height: 220px;

  * {
    color: white !important;
  }

  .contest-entry-bottom-div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
  }

  .contest-entry-pet-div {
    display: flex;
    align-items: center;
    gap: 16px;

    .contest-entry-pet-details {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .contest-entry-pet-photo {
      position: relative;

      .medal-frame {
        position: absolute;
        right: -8px;
        top: -8px;
      }
    }
  }

  &:disabled {
    opacity: 1 !important;
  }

  #contest-entry-card-name {
    margin: 0px;
    padding: 0px !important;
    width: auto !important;
  }
}
