.my-achievements-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  .achievements-container {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1200px;
    width: 90%;
    gap: 24px;
    padding-bottom: 32px;
  }
}
