.radio-select-div {
  min-height: 0px !important; 

  .MuiTabs-flexContainer {
    display: flex;
    gap: 16px;
  }

  button {
    font-size: 14px;
    font-weight: 500;
    padding: 8px 12px;
    background-color: rgba(121, 109, 241, 0.12);
    color: var(--purple);
    border-radius: 10px;
    text-transform: none;
    padding: 8px 12px;
    min-height: auto;
    min-width: auto;

    &.Mui-selected {
      background-color: var(--purple);
      color: white;
    }
  }
}
