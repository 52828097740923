.mobile-navbar {
  display: none;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 900;
  padding: 16px;
  justify-content: center;
  background-color: #fbfcff;

  .header-bttn {
    position: absolute;
    right: calc(5% - 8px);
    top: 44px;
    transform: translate(0%, -50%);
  }
}

@media only screen and (max-width: 595px) {
}

/* Small screen devices (600px and above) */
@media only screen and (max-width: 600px) {
}

/* Medium screen devices (768px and above) */
@media only screen and (max-width: 768px) {
}

/* Big screen devices (890px and above) */
@media only screen and (max-width: 890px) {
  .mobile-navbar {
    display: flex;
  }
}
