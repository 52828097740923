.payment-header-div {
  z-index: 999;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: #F5F5F5;
  border-radius: 30px;
  padding: 20px 40px;

  .payment-header-details {
    display: flex;
    gap: 24px;
    align-items: center;

    .details-txt {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
}

.payment-method-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;

  .content {
    margin-top: 115px;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 40px;
  }
}

@media only screen and (max-width: 595px) {
}

/* Small screen devices (600px and above) */
@media only screen and (max-width: 600px) {
}

/* Medium screen devices (768px and above) */
@media only screen and (max-width: 768px) {
}

/* Big screen devices (890px and above) */
@media only screen and (max-width: 890px) {
  .payment-header-div {
    padding: 20px 5%;
  }

  .payment-method-div {
    .content {
      padding: 40px 5% !important;
    }
  }
}
