.popular-pets-page {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  width: 100%;

  .popular-pets-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    max-width: 800px;
    padding: 0px 5%;
  }
}
