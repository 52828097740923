.photo-details-div {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;

  .photos-container {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;

    .pet-photo {
      width: 320px;
      height: 320px;
    }
  }
}
