.contest-chip {
  display: flex;
  align-items: center;
  gap: 12px;

  svg {
    width: 44px;
    height: 44px;
  }
}

.contest-chip-content h6 {
  white-space: nowrap;
}
