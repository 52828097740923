.my-pet-card-div {
  position: relative;
  width: 100%;
  max-width: 330px;
  padding: 10px;
  background-color: white;
  border-radius: 30px;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);

  .photo-wrapper {
    border-radius: 30px;
    width: 100%;
    overflow: hidden;
    padding-bottom: 90%;
    position: relative;
  }

  .card-content {
    padding: 10px;

    .card-header {
      display: flex;
      align-items: center;
      gap: 12px;
      margin-bottom: 8px;
    }

    .card-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .votes-div {
        display: flex;
        align-items: center;
        gap: 12px;

        svg {
          font-size: 24px;
          color: var(--purple);
        }

        p {
          font-weight: bold;
          color: var(--purple);
        }
      }
    }
  }

  .bttns-container {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 8px;
    display: flex;
    gap: 8px;

    button {
      background-color: white;
    }
  }
}
