.edit-pet-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  .edit-pet-content {
    flex: 1;
    width: 90%;
    max-width: 900px;
    background-color: white;
    border-radius: 60px 60px 0px 0px;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    padding: 48px 48px 32px;
    display: flex;
    flex-direction: column;
    gap: 32px;

    .edit-pet-form-wrapper {
      width: 100%;
      display: flex;
      gap: 72px;

      &> div {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 12px;
      }

      .input-container {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
    }

    .more-photos-section {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .more-photos-input-wrapper{
        display: flex;
        gap: 12px;
      }
    }
  }

  .action-bttns-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

@media only screen and (max-width: 595px) {
}

/* Small screen devices (600px and above) */
@media only screen and (max-width: 600px) {
}

/* Medium screen devices (768px and above) */
@media only screen and (max-width: 768px) {
}

/* Big screen devices (890px and above) */
@media only screen and (max-width: 890px) {

  .edit-pet-page {
    .edit-pet-content {
      padding: 0px 5% 24px;
      width: 100%;
      background-color: transparent;
      box-shadow: none;

      .edit-pet-form-wrapper {
        flex-direction: column;
        gap: 32px;
      }
    }

    .more-photos-section {
      width: 100%;

      .more-photos-input-wrapper{
        margin: auto;
        width: 100%;
        max-width: 250px;
        flex-direction: column;
      }
    }
  }
}
