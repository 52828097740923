.footer {
  width: 100%;
  padding: 40px 5%;
  border-top: 1px solid #F2F5FE;

  .top-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .social-media {
    display: flex;
    gap: 24px;
  }

  .txt-container {
    display: flex;
    gap: 32px;

    a {
      font-size: 0.8rem;
      color: var(--black);
    }
  }

  .bottom-section {
    margin-top: 40px;
    text-align: center;

    p {
      font-size: 0.8rem;
      color: var(--black);
    }
  }
}

@media only screen and (max-width: 595px) {}

/* Small screen devices (600px and above) */
@media only screen and (max-width: 600px) {}

/* Medium screen devices (768px and above) */
@media only screen and (max-width: 768px) {}

/* Big screen devices (890px and above) */
@media only screen and (max-width: 890px) {
  .footer {
    display: none;
  }
}
