.voter-item {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;

  .voter-profile {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 220px;

    h6 {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .voter-votes {
    display: flex;
    align-items: center;
    gap: 12px;
  }
}

@media only screen and (max-width: 595px) {
}

/* Small screen devices (600px and above) */
@media only screen and (max-width: 600px) {
}

/* Medium screen devices (768px and above) */
@media only screen and (max-width: 768px) {
}

/* Big screen devices (890px and above) */
@media only screen and (max-width: 890px) {
  .voter-item {
    justify-content: space-between;
  }
}
