.my-contests-page {
  position: relative;
  display: flex;
  flex: 1;
  gap: 32px;

  .my-contests {
    padding: 32px 0px;
    padding-left: 5%;
    width: calc(100vw - 450px);

    display: flex;
    flex-direction: column;
    gap: 24px;

    .contests-section {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 100%;

      .contests {
        margin-bottom: 32px;
        min-height: 220px;
        display: inline-flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 16px;
      }
    }
  }

  .my-contests-stats {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 450px;
    background-color: white;
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .who-voted-today-container {
    min-height: 100px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .my-recent-votes-container {
    min-height: 100px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

@media only screen and (max-width: 595px) {
}

/* Small screen devices (600px and above) */
@media only screen and (max-width: 600px) {
}

/* Medium screen devices (768px and above) */
@media only screen and (max-width: 768px) {
}

/* Big screen devices (890px and above) */
@media only screen and (max-width: 890px) {
  .my-contests-page {
    flex-direction: column;
    gap: 0px;
    margin: 0px 20px !important;
  }

  .my-contests-stats {
    position: static !important;
    width: 100% !important;
    background-color: transparent !important;
    padding: 0px !important;
  }

  .my-contests {
    width: 100% !important;
    padding: 0px !important;
    margin-top: 32px;
  }
}
