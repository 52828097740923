.my-purchases-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  .purchases-container {
    flex: 1;
    width: 90%;
    max-width: 900px;
    display: flex;
    flex-direction: column;

    .purchase-header {
      width: 100%;
      max-width: 900px;
      text-align: center;
      background-color: #f6f7fa;
      padding: 6px;
    }

    .purchase-content {
      width: 100%;
      max-width: 550px;
      margin: 24px auto;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left-side {
        display: flex;
        align-items: center;
        gap: 12px;
      }
    }
  }

  .no-purchases-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 595px) {
}

/* Small screen devices (600px and above) */
@media only screen and (max-width: 600px) {
}

/* Medium screen devices (768px and above) */
@media only screen and (max-width: 768px) {
}

/* Big screen devices (890px and above) */
@media only screen and (max-width: 890px) {
}
