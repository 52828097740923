.featured-entries {
  padding: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  .section-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }

  .pets-container {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 4px;
    width: 90%;
    max-width: 1000px;
  }
}

@media only screen and (max-width: 400px) {
}

@media only screen and (max-width: 595px) {
}

/* Small screen devices (600px and above) */
@media only screen and (max-width: 600px) {
}

/* Medium screen devices (768px and above) */
@media only screen and (max-width: 768px) {
}

/* Big screen devices (890px and above) */
@media only screen and (max-width: 890px) {
  .featured-entries {
    padding: 40px 0px !important;

    .pet-photo {
      min-height: 25vw !important;
      max-height: 25vw !important;
      min-width: 25vw !important;
      max-width: 25vw !important;
    }
  }
}
