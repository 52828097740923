.share-vote-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;

  .share-vote-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .share-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 330px;
    margin-top: 32px;
    text-align: center;
    gap: 24px;

    .share-container {
      display: flex;
      gap: 24px;
    }
  }

  .link-wrapper {
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    padding: 16px;
    margin-top: 24px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    h6 {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

@media only screen and (max-width: 595px) {
}

/* Small screen devices (600px and above) */
@media only screen and (max-width: 600px) {
}

/* Medium screen devices (768px and above) */
@media only screen and (max-width: 768px) {
}

/* Big screen devices (890px and above) */
@media only screen and (max-width: 890px) {
  .success-vote-div {
    padding: 52px 5% 40px;

    .share-content {
      margin-top: 12px;
    }
  }
}
