.add-pet-fab {
  .MuiFab-root {
    display: flex;
    gap: 8px;
    text-transform: none;
  }

  .fab-txt {
    font-weight: 600;
    color: white;
  }
}

