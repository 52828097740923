.mobile-contest-pet-card {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .mobile-contest-pet-card-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .name-div {
    display: flex;
    gap: 12px;
  }

  .state-div {
    display: flex;
    gap: 8px;

    svg {
      font-size: 24px;
      color: #e4e1f3;
    }
  }

  .chip-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .vote-button-div {
    display: flex;
    align-items: center;
    gap: 8px;

    svg {
      color: var(--purple);
    }
  }
}
