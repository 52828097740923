.all-tab-div {
  width: 100%;
  flex: 1;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;

  .no-pets-wrapper {
    min-height: 200px;
    display: flex;
    align-items: center;
  }
}
