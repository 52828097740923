.MuiBreadcrumbs-li {
  a,
  p {
    font-size: 14px;
  }
}

.MuiBreadcrumbs-separator {
  font-size: 14px;
}

@media only screen and (max-width: 595px) {
}

/* Small screen devices (600px and above) */
@media only screen and (max-width: 600px) {
}

/* Medium screen devices (768px and above) */
@media only screen and (max-width: 768px) {
}

/* Big screen devices (890px and above) */
@media only screen and (max-width: 890px) {
  .MuiBreadcrumbs-root {
    display: none;
  }
}
