.account-photo-wrapper {
  display: flex;
  justify-content: center;
  position: relative;
  padding: 16px;

  &.loading {
    width: 232px;
    height: 232px;
  }

  & > label { 
    display: flex;
  }

  .change-photo-bttn {
    position: absolute;
    right: 0px;
    bottom: 0px;

    svg {
      font-size: 1.5rem;
    }
  }

  svg {
    cursor: pointer;
  }

  input {
    display: none;
  }
}
