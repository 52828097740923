.pet-card-div {
  width: 100%;
  position: relative;
  height: fit-content;
  width: 100%;
  max-width: 330px;
  padding: 10px;
  background-color: white;
  border-radius: 30px;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);

  &:disabled {
    opacity: 1 !important;
  }

  .photo-wrapper {
    border-radius: 30px;
    width: 100%;
    overflow: hidden;
    padding-bottom: 90%;
    position: relative;
  }

  .card-content {
    display: flex;
    padding: 10px;

    .card-txt {
      display: flex;
      flex: 1;
      flex-direction: column;
      gap: 8px;

      .name-div {
        display: flex;
        align-items: center;
        gap: 12px;
      }

      .votes-div {
        display: flex;
        align-items: center;
        gap: 8px;

        svg {
          font-size: 24px;
          color: var(--purple);
        }

        p {
          font-weight: bold;
          color: var(--purple);
        }
      }

      .state-div {
        display: flex;
        align-items: center;
        gap: 8px;

        svg {
          font-size: 24px;
          color: #e4e1f3;
        }
      }
    }
  }

  .prize-circle {
    position: absolute;
    right: 24px;
    top: 24px;
    height: 84px;
    width: 84px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 3px solid #ffffff;
    background: linear-gradient(73.57deg, #7367f0 4.6%, #9e95f5 96.49%);
    border-radius: 50%;

    .MuiTypography-subtitle2 {
      font-size: 0.75rem;
      font-weight: bold !important;
      margin-top: -4px;
    }

    * {
      color: white !important;
    }
  }
}

@media only screen and (max-width: 595px) {
}

/* Small screen devices (600px and above) */
@media only screen and (max-width: 600px) {
}

/* Medium screen devices (768px and above) */
@media only screen and (max-width: 768px) {
}

/* Big screen devices (890px and above) */
@media only screen and (max-width: 890px) {
}
