.checkout-form-div {
  width: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: column;

  .payment-form-wrapper {
    border: 1px solid #D9D9D9;
    border-radius: 15px;
    margin-top: 32px;
    padding: 16px;
  }
}
