.currently-winning-container {
  width: 100%;
  display: flex;
  flex-direction: column;

  .winning-value {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: 595px) {
}

/* Small screen devices (600px and above) */
@media only screen and (max-width: 600px) {
}

/* Medium screen devices (768px and above) */
@media only screen and (max-width: 768px) {
}

/* Big screen devices (890px and above) */
@media only screen and (max-width: 890px) {
  .currently-winning-container {
    align-items: center;
  }
}
