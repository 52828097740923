.desktop-navbar {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 900;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f2f5fe;

  padding: 16px 5%;

  .bttns-container {
    display: flex;
    gap: 32px;
  }

  .right-navbar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
  }
}

.header-bttn {
  &.active p {
    color: var(--purple);
  }
}

.search-container {
  margin-left: 24px;
  display: flex;
  align-items: center;
  gap: 12px;
  flex: 1;
  max-width: 400px;
}

@media only screen and (max-width: 595px) {
}

/* Small screen devices (600px and above) */
@media only screen and (max-width: 600px) {
}

/* Medium screen devices (768px and above) */
@media only screen and (max-width: 768px) {
}

/* Big screen devices (890px and above) */
@media only screen and (max-width: 890px) {
  .desktop-navbar {
    display: none;
  }
}

/* Big screen devices (1200px and above) */
@media only screen and (max-width: 1200px) {
  .desktop-navbar-logo {
    display: none;
  }
}
