.connect-facebook-div {
  background: #f6f7fa;
  height: 100%;
  padding: 30px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  gap: 24px;
}
