.change-account-details-field {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.change-account-details-buttons {
  display: flex;
  gap: 8px;
}

@media only screen and (max-width: 595px) {
}

/* Small screen devices (600px and above) */
@media only screen and (max-width: 600px) {
}

/* Medium screen devices (768px and above) */
@media only screen and (max-width: 768px) {
}

/* Big screen devices (890px and above) */
@media only screen and (max-width: 890px) {
  .change-account-details-buttons {
    flex-direction: column;
  }
}
