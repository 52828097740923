.mobile-menu {
  position: absolute;
  top: 0;
  left: 0;
  min-height: calc(calc(var(--vh, 1vh) * 100));
  width: 100vw;
  background-color: #fbfcff;
  z-index: 999;

  .mobile-menu-container-wrapper {
    margin-top: 88px;
    max-height: calc(calc(var(--vh, 1vh) * 100) - 88px);
    overflow: auto;
  }

  .mobile-menu-container {
    padding: 40px 5%;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .login-div {
    display: flex;
    gap: 32px;
    padding-bottom: 20px;
  }

  h5,
  h6 {
    font-weight: 400 !important;
  }

  .close-menu-bttn {
    position: absolute;
    right: calc(5% - 8px);
    top: 44px;
    transform: translate(0%, -50%);
    z-index: 1000;
  }
}

.mobile-menu-item {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .menu-item-left {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  svg {
    font-size: 2rem;
  }

  .menu-collapse-icon {
    transform: rotate(180deg);

    &.expanded {
      transform: rotate(0deg);
    }
  }
}

.sub-menu-container {
  margin-left: 52px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.mobile-menu-item-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}
