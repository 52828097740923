.past-contest-details-page {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 90%;

  .past-contest-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    gap: 16px;
    padding-bottom: 64px;
  }
}
