.winners-page {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  .winners-content {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    margin-top: 12px;
    gap: 32px;
    padding-bottom: 64px;
  }
}
