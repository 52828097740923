.cropper-main-div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 40px;
  width: 800px;
  height: 500px;
  max-height: 90%;
  max-width: 90%;
  border-radius: 30px;

  display: flex;
  flex-direction: column;
  gap: 12px;
}

@media only screen and (max-width: 595px) {
}

/* Small screen devices (600px and above) */
@media only screen and (max-width: 600px) {
}

/* Medium screen devices (768px and above) */
@media only screen and (max-width: 768px) {
}

/* Big screen devices (890px and above) */
@media only screen and (max-width: 890px) {
  .cropper-main-div {
    padding: 24px;
  }
}
