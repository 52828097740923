.faq {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 0px;
  background: linear-gradient(51.64deg, #7367f0 25.43%, #857bf2 55.41%, #9e95f5 96.09%);
  gap: 32px;

  .txt-div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .faqs-container {
    width: 90%;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .faq-accordion {
    background-color: transparent;
    border-radius: 16px;
    border: 2px solid #ffffff;

    .MuiSvgIcon-root,
    .faq-question-txt {
      color: white;
    }

    &.Mui-expanded {
      .MuiSvgIcon-root,
      .faq-question-txt {
        color: #ffbc01;
      }
    }
  }
}

@media only screen and (max-width: 360px) {
}

@media only screen and (max-width: 595px) {
}

/* Small screen devices (600px and above) */
@media only screen and (max-width: 600px) {
}

/* Medium screen devices (768px and above) */
@media only screen and (max-width: 768px) {
}

/* Big screen devices (890px and above) */
@media only screen and (max-width: 890px) {
}
