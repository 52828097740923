.welcome-modal-div {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;

  width: 90%;
  max-width: 500px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  padding: 40px;
  border-radius: 16px;
  text-align: center;

  .header-txt {
    font-weight: bold;
  }

  .bttns-container {
    margin: 12px 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .close-bttn {
    position: absolute;
    top: 40px;
    right: 40px;
    padding: 0px;

    svg {
      font-size: 1.3rem;
    }
  }

  .input-wrapper {
    width: 100%;
    margin-top: 24px;
    margin-bottom: 12px;
  }

  .timer-div {
    margin-top: 12px;
    text-align: center;

    span {
      font-weight: bold;
      font-size: 1.2rem;
      color: var(--purple);
      margin-right: 8px;
    }
  }
}

@media only screen and (max-width: 595px) {
}

/* Small screen devices (600px and above) */
@media only screen and (max-width: 600px) {
}

/* Medium screen devices (768px and above) */
@media only screen and (max-width: 768px) {
}

/* Big screen devices (890px and above) */
@media only screen and (max-width: 890px) {
  .welcome-modal-div {
    padding: 32px 24px;
  }
  .close-bttn {
    top: 32px !important;
    right: 24px !important;
  }
}
