.pet-photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 0% 0%;

  &.fake {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    background: linear-gradient(73.57deg, #7367f0 4.6%, #9e95f5 96.49%);

    h6 {
      color: #252527;
    }
  }
}
