.search-page {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px;

  .search-wrapper {
    background-color: white;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  }

  .search-results-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 24px;
    padding: 0px 0px 24px;
  }

  .no-pets-txt {
    margin: auto;
  }
}
