.pet-details-content {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 90%;
  max-width: 1000px;
  gap: 40px;
  padding-top: 24px;
  padding-bottom: 64px;
}
