.bottom-tabs-wrapper {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  height: 64px;
  z-index: 999;
}

.bottom-tab-item {
  width: 20vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0px !important;
  gap: 2px;

  .label-txt {
    font-size: 0.7rem;
  }

  &.active .label-txt {
    color: var(--purple);
  }

  &.active svg {
    color: var(--purple);
  }

  svg {
    font-size: 1.4rem;
  }
}

@media only screen and (max-width: 595px) {
}

/* Small screen devices (600px and above) */
@media only screen and (max-width: 600px) {
}

/* Medium screen devices (768px and above) */
@media only screen and (max-width: 768px) {
}

/* Big screen devices (890px and above) */
@media only screen and (max-width: 890px) {
  .bottom-tabs-wrapper {
    display: flex;
  }
}
