.create-pet-form {
  flex: 1;
  background-color: white;
  border-radius: 60px 60px 0px 0px;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
  padding: 48px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  .containers-wrapper {
    flex: 1;
    display: flex;
    gap: 72px;
  }

  .left-container {
    width: 35vw;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .left-containre-inputs {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }

  .right-container {
    width: 35vw;
    max-width: 400px;

    .input-container {
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }

  .publish-bttn-wrapper {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    background-color: white;
  }
}

@media only screen and (max-width: 595px) {
}

/* Small screen devices (600px and above) */
@media only screen and (max-width: 600px) {
}

/* Medium screen devices (768px and above) */
@media only screen and (max-width: 768px) {
}

/* Big screen devices (890px and above) */
@media only screen and (max-width: 890px) {
  .create-pet-form {
    width: 100%;
    background-color: transparent;
    box-shadow: none;
    padding: 0px;
    gap: 0px !important;

    .containers-wrapper {
      width: 100%;
      flex-direction: column;
      gap: 0px !important;
    }

    .left-container {
      width: 100%;
      max-width: none;

      .add-photo-wrapper {
        padding: 0px 5% 8px;
      }

      .left-containre-inputs {
        border-radius: 0px 60px 0px 0px;
        padding: 20px 5% 0px;
        background-color: white;
      }
    }

    .right-container {
      padding: 24px 5%;
      width: 100%;
      max-width: none;
      background-color: white;
    }

    .publish-bttn-wrapper {
      padding-bottom: 32px;
    }

    .mobile-participate-contest {
      background-color: white;
      width: 100%;
      flex-direction: column;
      gap: 16px;
      align-items: center;
      padding: 0px 5% 16px;
    }
  }
}
