.account-menu {
  border: 1px solid var(--border);
  border-radius: 6px;

  .MuiMenuItem-root {
    display: flex;
    gap: 12px;
    height: 40px;

    &:hover {
      background-color: rgba(126, 115, 242, 0.12);
    }
  }
}
