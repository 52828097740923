.my-account-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  .my-account-content {
    flex: 1;
    width: 90%;
    max-width: 900px;
    background-color: white;
    border-radius: 60px 60px 0px 0px;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    padding: 48px 48px 32px;

    display: flex;
    flex-direction: column;
  }

  .fields-container {
    width: 100%;
    display: flex;
    gap: 40px;

    & > div {
      flex: 1;
      display: flex;
      flex-direction: column;

      &.left-container {
        gap: 32px;
        align-items: center;
      }

      &.right-container {
        gap: 32px;
      }
    }
  }
}

.my-account-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr auto;
  gap: 32px;
  grid-template-areas:
    'add-photo form-fields'
    'connect-fb notifications-form'
    'delete-bttn .';

  .add-photo {
    grid-area: add-photo;
    width: 200px;
    margin: auto;
  }

  .form-fields {
    grid-area: form-fields;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .connect-fb {
    grid-area: connect-fb;
  }

  .notifications-form {
    padding-bottom: 30px;
    grid-area: notifications-form;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 32px;
  }

  .delete-bttn {
    grid-area: delete-bttn;
    text-align: center;
  }
}

@media only screen and (max-width: 595px) {
}

/* Small screen devices (600px and above) */
@media only screen and (max-width: 600px) {
}

/* Medium screen devices (768px and above) */
@media only screen and (max-width: 768px) {
}

/* Big screen devices (890px and above) */
@media only screen and (max-width: 890px) {
  .my-account-container {
    grid-template-rows: repeat(5, auto);
    grid-template-columns: 1fr;
    gap: 0px;
    grid-template-areas:
      'add-photo'
      'form-fields'
      'connect-fb'
      'notifications-form'
      'delete-bttn';

    .add-photo {
    }

    .form-fields {
      margin-top: 16px;
      border-radius: 0px 60px 0px 0px;
      padding: 32px 5%;
      background-color: white;
      gap: 24px;
    }

    .connect-fb {
      padding: 0px 5% 32px;
      background-color: white;
    }

    .notifications-form {
      padding: 0px 5% 0px;
      background-color: white;
    }

    .delete-bttn {
      padding: 0px 5% 24px;
      background-color: white;
    }
  }

  .my-account-content {
    width: 100% !important;
    background-color: transparent !important;
    border-radius: 0px !important;
    box-shadow: none !important;
    padding: 0px !important;
  }
}
