.medal-badge {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  align-items: center;
  background-color: #F6F7FA;
  border-radius: 10px;
  min-height: 44px;
  min-width: 44px;
  height: 100%;
}
