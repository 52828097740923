.past-contest-card-div {
  width: 90vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  max-height: 350px;
  height: 59vw;
  min-height: 250px;
  max-width: 700px;
  padding: 54px 68px 24px;

  background-repeat: no-repeat;
  background-position: 75% 100%;
  border-radius: 30px;
  background-size: auto 90%;

  border-radius: 30px;

  .top-div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    & > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .bottom-div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }

  * {
    color: white !important;
  }

  &:disabled {
    opacity: 1 !important;
  }
}

@media only screen and (max-width: 595px) {
}

/* Small screen devices (600px and above) */
@media only screen and (max-width: 600px) {
}

/* Medium screen devices (768px and above) */
@media only screen and (max-width: 768px) {
}

/* Big screen devices (890px and above) */
@media only screen and (max-width: 890px) {
  .past-contest-card-div {
    padding: 32px 32px 16px;
    gap: 24px;
  }
}
