.prize-tab-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  width: 100%;
  max-width: 750px;
}

.prizes-card {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  max-width: 750px;
  background-color: white;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
  border-radius: 30px;

  .header {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(73.57deg, #7367f0 4.6%, #9e95f5 96.49%);
    border-radius: 30px;

    .decore-left {
      position: absolute;
      left: 0;
      border-top-left-radius: 30px;
      width: 33%;
      max-width: 200px;
    }

    .decore-right {
      position: absolute;
      right: 0;
      border-top-right-radius: 30px;
      width: 33%;
      max-width: 200px;
    }

    .first-prize-txt {
      padding: 16px 0px;
      color: white;
    }
  }

  .table {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    width: 100%;
    padding: 0px 24px 24px;

    .top-section {
      display: flex;
      gap: 36px;
    }

    .bottom-section {
      width: 100%;
    }
  }
}

.top-prize-chip-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  .txt-div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.prize-table-line {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
  padding: 10px 0px;

  &.dark {
    background-color: #f6f7fa;
  }

  .label-txt {
    width: 80px;
    text-align: end;
  }
}
