.choose-votes-div {
  display: flex;
  flex-direction: column;
  padding: 40px;
  flex: 1;

  .choose-votes-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 32px;
  }

  ul {
    margin-top: 24px;
    padding: 0px;
    width: 100%;
    list-style-type:none;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}

.vote-row-item {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;

  .vote-icon {
    height: 40px;
    width: 40px;
  }

  .vote-rows-details {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.5px solid #B5AFF3;
    padding-bottom: 12px;

    .vote-row-details-txt {
      display: flex;
      flex-direction: column;
    }
  }

  .meals-div {
    display: flex;
    gap: 8px;
    align-items: center;
  }
}

@media only screen and (max-width: 595px) {
}

/* Small screen devices (600px and above) */
@media only screen and (max-width: 600px) {
}

/* Medium screen devices (768px and above) */
@media only screen and (max-width: 768px) {
}

/* Big screen devices (890px and above) */
@media only screen and (max-width: 890px) {
  .vote-row-item {
    gap: 12px !important;
  }
}

@media only screen and (max-height: 800px) {
  .vote-row-item .vote-row-details-txt{
    flex-direction: row !important;
  }
}
