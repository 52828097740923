.my-pets-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  .my-pets-content {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 64px;
    padding-bottom: 64px;
  }

  .add-pet-bttn-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .my-pets-header-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 595px) {
}

/* Small screen devices (600px and above) */
@media only screen and (max-width: 600px) {
}

/* Medium screen devices (768px and above) */
@media only screen and (max-width: 768px) {
}

/* Big screen devices (890px and above) */
@media only screen and (max-width: 890px) {
  .my-pets-header-wrapper {
    justify-content: space-between !important;
  }
}
