.mobile-pet-details {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 100%;

  .pet-photo-wrapper {
    display: flex;
    align-items: center;
    position: fixed;
    //margin-top: -86px;
    width: 100%;
    height: 75vw;
  }

  .mobile-pet-details-card {
    //margin-top: calc(calc(80vw * 0.75) - 86px);
    margin-top: calc(80vw * 0.75);
    flex: 1;
    background-color: white;
    z-index: 2;
    border-radius: 0px 60px 0px 0px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
    padding: 30px 5%;
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .vote-rows-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
