.local-tab-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  width: 90%;

  .state-list-wrapper {
    width: 100%;
    max-width: 820px;
  }

  .pets-div {
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 24px;
  }

  .no-pets-wrapper {
    min-height: 200px;
    display: flex;
    align-items: center;
  }
}
