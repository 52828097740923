.loading-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: #f6f7fa;
  border-radius: 10px;
  font-size: 2.5rem;
  animation: color 1s linear infinite alternate;
  margin: auto;
}

@keyframes color {
  from {
    color: #cfd6e8;
  }
  to {
    color: var(--purple);
  }
}
