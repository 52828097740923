.achievement-card-container {
  position: relative;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
  width: 380px;
  padding: 20px 0px;
  border-radius: 30px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  .votes-div {
    display: flex;
    align-items: center;
    gap: 8px;

    svg {
      font-size: 24px;
      color: var(--purple);
    }

    p {
      font-weight: 500;
      color: var(--purple);
    }
  }

  .details-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    max-width: 90%;
    text-align: center;
  }

  .checkmark-icon {
    position: absolute;
    right: 12px;
    top: 12px;
    height: 40px;
    width: 40px;
  }
}
