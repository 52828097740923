.no-pets-div {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 40px;

  img {
    border-radius: 30px;
  }
}
