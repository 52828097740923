.how-it-works {
  position: relative;
  height: calc(calc(var(--vh, 1vh) * 100) - 86px);
  background: linear-gradient(34.18deg, #aea0bc 20.22%, #d4cbde 80.18%);

  .how-it-works-background {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
  }

  .txt-section {
    width: 320px;
    position: absolute;
    top: 50%;
    right: 22%;
    transform: translate(0%, -50%);
    display: flex;
    flex-direction: column;
    gap: 24px;
    text-align: center;

    .sub-section {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
  }

  #how-it-works-image {
    position: absolute;
    bottom: 0px;
    left: 15%;
    width: 50%;
    max-width: 600px;
  }
}

@media only screen and (max-width: 595px) {
}

/* Small screen devices (600px and above) */
@media only screen and (max-width: 600px) {
}

/* Medium screen devices (768px and above) */
@media only screen and (max-width: 768px) {
}

/* Big screen devices (890px and above) */
@media only screen and (max-width: 890px) {
  .how-it-works {
    height: calc(calc(var(--vh, 1vh) * 100) - 64px);
    display: flex;
    flex-direction: column;

    .txt-section {
      flex: 1;
      width: 100%;
      position: static;
      transform: translate(0%, 0%) !important;
      padding: 100px 16px 40px;
      justify-content: space-between;
      align-items: center;

      .sub-section {
        max-width: 70%;
      }
    }

    #how-it-works-image {
      transform: translate(0%, 0%) !important;
      left: -10% !important;
      width: 70% !important;
    }
  }
}
